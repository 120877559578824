import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./layout.scss"
import { StaticImage } from "gatsby-plugin-image"
import {
  faCodepen,
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
type Props = {
  children: any
}

const Layout = (props: Props) => {
  const [showMenu, setShowMenu] = useState(true)

  function detectMob(): boolean {
    return window.innerWidth <= 600 || window.innerHeight <= 600
  }

  useEffect(() => {
    setShowMenu(!detectMob())
  }, [])

  return (
    <>
      <header className="header">
        <div className="menu-mobile-link">
          <FontAwesomeIcon
            onClick={() => setShowMenu(!showMenu)}
            icon={showMenu ? faTimes : faBars}
          />
        </div>
        <Link to="/">
          <StaticImage
            alt="Site logo"
            className="logo"
            src="../../images/logo.svg"
          />
        </Link>
        {showMenu && (
          <ul className="menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/projects">Projects</Link>
            </li>
          </ul>
        )}
      </header>
      {props.children}
      <footer className="footer">
        <div className="data">
          <div className="contact">
            {/* <label>
                            <a href="mailto:contacto@ricardocolin.com">contacto@ricardocolin.com</a>
                        </label> */}
            <label>
              <a
                target="_blank"
                href="https://github.com/rysard/"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </label>
            <label>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/ricardo-colin-09218a121/"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </label>
            <label>
              <a
                target="_blank"
                href="https://codepen.io/ricardocolin/"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faCodepen} />
              </a>
            </label>
            <label>
              <a
                target="_blank"
                href="https://twitter.com/ricardo_colin12"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </label>
          </div>
        </div>
        <div className="copy">
          <p>&#169; Copyright 2021 ricardocolin.com - All rights reserved</p>
        </div>
      </footer>
    </>
  )
}

export default Layout
